import {Component} from '@angular/core';
import {EMPTY} from "rxjs";
import {AdminFrontSharedModule} from "../../shared/admin-front-shared.module";
import {AsyncPipe, NgIf} from "@angular/common";
import {Router, RouterOutlet} from "@angular/router";
import {TabMenuModule} from "primeng/tabmenu";
import {TableNavigatorModule} from "@fiscalteam/ngx-nitro-services";
import {NewUserFormInputModule} from "@fiscalteam/ngx-nitro-services";

@Component({
  selector: 'adm-nitro-user-new-route',
  standalone: true,
  imports: [
    AdminFrontSharedModule,
    AsyncPipe,
    NgIf,
    RouterOutlet,
    TabMenuModule,
    TableNavigatorModule,
    NewUserFormInputModule,
    NgIf,
  ],
  templateUrl: './nitro-user-new-route.component.html',
  styleUrl: './nitro-user-new-route.component.scss'
})
export class NitroUserNewRouteComponent {

  protected readonly pageTitle$ = EMPTY;
  protected readonly tabMenu$ = EMPTY;

  constructor(private router: Router) {
  }

  onUserCreated() {
    this.router.navigate(['/user/list']);
  }

}
