import {Injectable} from "@angular/core";
import {ConfigService} from "@fiscalteam/ngx-nitro-services";
import {AppShellMenuItem} from "../shell/app-shell/app-shell-menu/app-shell-menu-item";
import {AdminFrontMainMenu} from "../shell/admin-front-menu";
import {WsNitroRuleActionType} from "@fiscalteam/nitro-domain-client";
import {AdminFrontConfigKeys} from "./admin-front-config-keys";

/**
 * A service to resolve this application's configuration.
 */
@Injectable({
  providedIn: 'root'
})
export class AdminFrontConfigService {

  private runtimeAdminAppFeatures: Record<AdminFrontConfigKeys, boolean>;

  constructor(
    private nitroConfigService: ConfigService,
  ) {
    this.runtimeAdminAppFeatures = this.configureRuntimeAppFeatures();
    console.log([`Configured admin front features`, this.runtimeAdminAppFeatures]);
  }

  createMainMenu(): AppShellMenuItem[] {
    return this.filterMenuItems(AdminFrontMainMenu);
  }

  filterMenuItems(items: AppShellMenuItem[]): AppShellMenuItem[] {
    return items.filter(menuItem => this.isMenuItemVisible(menuItem))
      .map(menuItem => this.filterMenuItemChildren(menuItem));
  }

  isFeatureEnabled(adminAppFeature: AdminFrontConfigKeys): boolean {
    if (this.runtimeAdminAppFeatures[adminAppFeature] != null) {
      return this.runtimeAdminAppFeatures[adminAppFeature];
    } else {
      return this.nitroConfigService.getConfigValue(adminAppFeature) === "true";
    }
  }

  getCodaImportApiUri(): string {
    const configKey: AdminFrontConfigKeys = "codabox.importer.api.uri";
    return this.nitroConfigService.getConfigValue(configKey);
  }

  getkeycloakUrl(): string {
    return this.nitroConfigService.getConfigValue('keycloak.url');
  }

  getIndexerDocumentUri(documentId: number): string {
    const indexerUrl = this.nitroConfigService.getConfigValue('indexer.ui.url');
    return `${indexerUrl}?customerDocumentId=${documentId}`;
  }

  getIndexerCustomerUri(customerId: number): string {
    const indexerUrl = this.nitroConfigService.getConfigValue('indexer.ui.url');
    return `${indexerUrl}/list?customerId=${customerId}`;
  }

  getAvailableNitroRuleActionTypes() {
    const availableActions: WsNitroRuleActionType[] = [];
    Object.values(WsNitroRuleActionType)
      .map(t => t as WsNitroRuleActionType)
      .forEach(a => {
        if (this.checkRUleActionEnabled(a)) {
          availableActions.push(a);
        }
      });
    return availableActions;
  }

  private checkRUleActionEnabled(a: WsNitroRuleActionType) {
    switch (a) {
      case WsNitroRuleActionType.SuggestDocumentTypes:
        return this.isFeatureEnabled('menu.config.rules.SuggestDocumentTypes.visible');
      case WsNitroRuleActionType.UseFallbackDirection:
        return this.isFeatureEnabled('menu.config.rules.UseFallbackDirection.visible');
      case WsNitroRuleActionType.UseFallbackThirdparty:
        return this.isFeatureEnabled('menu.config.rules.UseFallbackThirdparty.visible');
      case WsNitroRuleActionType.SetDocumentBookable:
        return this.isFeatureEnabled('menu.config.rules.SetDocumentBookable.visible');
      case WsNitroRuleActionType.SetGlaccount:
        return this.isFeatureEnabled('menu.config.rules.SetGlaccount.visible');
      case WsNitroRuleActionType.SetThirdpartyOverride:
        return this.isFeatureEnabled('menu.config.rules.SetThirdpartyOverride.visible');
      case WsNitroRuleActionType.SetProblematic:
        return this.isFeatureEnabled('menu.config.rules.SetProblematic.visible');
      default:
        throw new Error(`No feature for rula action ${a}`);
    }
  }

  private isMenuItemVisible(menuItem: AppShellMenuItem): boolean {
    const enabledRequirements = menuItem.requiredEnabledFeatures || [];
    if (enabledRequirements.length > 0) {
      const disabledRequirement = enabledRequirements.find(f => !this.isFeatureEnabled(f));
      if (disabledRequirement != null) {
        return false;
      }
    }

    const disabledRequirements = menuItem.requiredDisabledFeatures || [];
    if (disabledRequirements.length > 0) {
      const enabledRequirement = disabledRequirements.find(f => this.isFeatureEnabled(f));
      if (enabledRequirement != null) {
        return false;
      }
    }

    return true;
  }

  private filterMenuItemChildren(menuItem: AppShellMenuItem): AppShellMenuItem {
    const children = menuItem.items;
    if (children) {
      const filteredChildren = children.filter(c => this.isMenuItemVisible(c))
        .map(c => this.filterMenuItemChildren(c));
      return Object.assign({}, menuItem, {
        items: filteredChildren
      } as Partial<AppShellMenuItem>);
    } else {
      return menuItem;
    }
  }


  private configureRuntimeAppFeatures(): Record<AdminFrontConfigKeys, boolean> {
    const runttimeFeatures: Record<AdminFrontConfigKeys, boolean> = {} as Record<AdminFrontConfigKeys, boolean>;

    // NITRO-434: Disable indexing phase features if documents are not indexed.
    const indexingEnabled = this.nitroConfigService.getConfigValue("document.indexing.enabled", "true") === 'true';
    // NITRO-506: Disable configuration phase features if documents are not configured
    const configurationEnabled = this.nitroConfigService.getConfigValue("document.configuration.enabled", "true") === 'true';
    const exportEnabled = this.nitroConfigService.getConfigValue("document.export.enabled", "true") === "true";

    // indexed documents are in the configuration phase when enabled, or stuck there when configuration and export both disabled
    runttimeFeatures["data.configuration.visible"] = configurationEnabled || !exportEnabled;
    runttimeFeatures["data.export.visible"] = exportEnabled;

    return runttimeFeatures;
  }

}
