import {AccountingDataDashboardRouteComponent} from "./accounting-data-dashboard-route/accounting-data-dashboard-route.component";
import {Route} from "@angular/router";

export const AdminFrontRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    component: AccountingDataDashboardRouteComponent,
  },
  {
    path: 'batch',
    loadChildren: () => import('../batch/batch.module').then(mod => mod.BatchModule)
  },
  {
    path: 'user',
    loadChildren: () => import('../nitro-user/nitro-user.module').then(mod => mod.NitroUserModule)
  },
  {
    path: 'financialProviders',
    loadChildren: () => import('../financial-provider/financial-providers.module').then(mod => mod.FinancialProvidersModule)
  },
  {
    path: 'businessSector',
    loadChildren: () => import('../business-sector/business-sector.module').then(mod => mod.BusinessSectorModule)
  },
  {
    path: 'configuration',
    loadChildren: () => import('../configuration/configuration.module').then(mod => mod.ConfigurationModule),
  },
  {
    path: 'customer',
    loadChildren: () => import('../customer/customer.module').then(mod => mod.CustomerModule)
  },
  {
    path: 'customerTransaction',
    loadChildren: () => import('../customer-transaction/customer-transaction.module').then(mod => mod.CustomerTransactionModule)
  },
  {
    path: 'customerDocument',
    loadChildren: () => import('../customer-document/customer-document.module').then(mod => mod.CustomerDocumentModule)
  },
  {
    path: 'document-type-collection',
    loadChildren: () => import('../document-type-collection/admin-front-document-type-collection.module').then(mod => mod.AdminFrontDocumentTypeCollectionModule)
  },
  {
    path: 'problem',
    loadChildren: () => import('../document-problem/document-problem.module').then(mod => mod.AccountingDataProblemModule)
  },
  {
    path: 'rule',
    loadChildren: () => import('../rule/rule.module').then(mod => mod.RuleModule)
  },
  {
    path: 'ruleAction',
    loadChildren: () => import('../rule-action/rule-action.module').then(mod => mod.RuleActionModule)
  },
  {
    path: 'thirdparty',
    loadChildren: () => import('../thirdparty/thirdparty.module').then(mod => mod.ThirdpartyModule)
  },
  {
    path: 'thirdpartyEntity',
    loadChildren: () => import('../thirdparty-entity/thirdparty-entity.module').then(mod => mod.ThirdpartyEntityModule)
  },
  {
    path: 'thirdpartyEntityIdentifier',
    loadChildren: () => import('../thirdparty-entity-identifier/thirdparty-entity-identifier.module').then(mod => mod.ThirdpartyEntityIdentifierModule)
  },
  {
    path: 'trustee',
    loadChildren: () => import('../trustee/trustee.module').then(mod => mod.TrusteeModule)
  },
];
