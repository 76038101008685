<adm-app-shell-page >
  <ng-template admShellPageTitle>
   Nouvel utilisateur
  </ng-template>


  <div class="nitro-user-form h-full mr-4">
    <nit-new-user-form-input (userCreated)="onUserCreated()">
    </nit-new-user-form-input>
  </div>

</adm-app-shell-page>
