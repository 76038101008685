import {AppShellMenuItem} from "./app-shell/app-shell-menu/app-shell-menu-item";
import {WellKnownThirdpartyFilters} from "./well-known-thirdparty-filters";
import {countProblems$, WellKnownAccountingDataProblemsFilters} from "./well-known-document-problems-filters";
import {CombinedFilterFilterProperties, FilterPropertiesUtils, NitroRuleFilterProperties, ThirdPartyEntityIdentifierFilterProperties} from "@fiscalteam/ngx-nitro-services";
import {ShellRoutesUtils} from "./shell-routes.utils";
import {WsThirdPartyIdentifierType} from "@fiscalteam/nitro-domain-client";
import {WellKnownCustomerDocumentFilters} from "./well-known-customer-document-filters";
import {WellKnowCustomerTransactionFilters} from "./WellKnowCustomerTransactionFilters";


export const AccoungindDataDashboardSubItem: AppShellMenuItem = {
  label: `Aperçu`,
  routerLink: ['/'],
  requiredEnabledFeatures: ["menu.data.dashboard.visible"],
};

// Documents

export const CustomerDocumentToValidateSubItem: AppShellMenuItem = {
  label: 'A valider',
  routerLink: ['/customerDocument/tovalidate', {filter: FilterPropertiesUtils.serializeFilterPropertyValues(WellKnownCustomerDocumentFilters.status_to_validate)}],
  requiredEnabledFeatures: ["menu.document.visible"]
};

export const CustomerDocumentProblematicSubItem: AppShellMenuItem = {
  label: 'En problème',
  routerLink: ['/customerDocument/problematic', {filter: FilterPropertiesUtils.serializeFilterPropertyValues(WellKnownCustomerDocumentFilters.problematic)}],
  requiredEnabledFeatures: ["menu.document.visible"]
};
export const CustomerDocumentSubItem: AppShellMenuItem = {
  label: `Documents`,
  routerLink: ['/customerDocument/list', {filter: FilterPropertiesUtils.serializeFilterPropertyValues(WellKnownCustomerDocumentFilters.all)}],
  // FIXME: does not work
  // routerLink: ShellRoutesUtils.customerDocumentListForFiltersFactory([WellKnownCustomerDocumentFilters.all]),
  requiredEnabledFeatures: ["menu.document.visible"],
  items: [
    CustomerDocumentProblematicSubItem,
    CustomerDocumentToValidateSubItem
  ]
};

// Transactions

export const CustomerTransactionsProblematicSubItem: AppShellMenuItem = {
  label: 'En problème',
  routerLink: ['/customerTransaction/problematic', {filter: FilterPropertiesUtils.serializeFilterPropertyValues(WellKnowCustomerTransactionFilters.problematic)}],
  requiredEnabledFeatures: ["menu.customerBankAccountAll.visible"]
};

export const CustomerTransactionsToValidateSubItem: AppShellMenuItem = {
  label: 'A valider',
  routerLink: ['/customerTransaction/tovalidate', {filter: FilterPropertiesUtils.serializeFilterPropertyValues(WellKnowCustomerTransactionFilters.statut)}],
  requiredEnabledFeatures: ["menu.customerBankAccountTovalidate.visible"]
};


export const CustomerTransactionsSubItem: AppShellMenuItem = {
  label: 'Transactions',
  routerLink: ShellRoutesUtils.customerTransactionListForFiltersFactory([]),
  requiredEnabledFeatures: ["menu.customerBankAccount.visible"],
  items: [
    CustomerTransactionsProblematicSubItem,
    CustomerTransactionsToValidateSubItem
  ]
};

export const AccountingDataMainItem: AppShellMenuItem = {
  label: 'Pièces comptables',
  icon: 'pi pi-file-o',
  routerLink: ['/'],
  requiredEnabledFeatures: ["menu.data.visible", "menu.data.dashboard.visible"],
  items: [
    CustomerDocumentSubItem,
    CustomerTransactionsSubItem
  ]
}


// ThirdParty
export const ThirdPartyAllSubItem: AppShellMenuItem = {
  label: `Tous les tiers`,
  routerLink: ['/thirdparty/list', {filter: FilterPropertiesUtils.serializeFilterPropertyValues(WellKnownThirdpartyFilters.all)}],
  requiredEnabledFeatures: ["menu.thirdparty.visible"],
};
export const ThirdPartyIdentifiersSubItem: AppShellMenuItem = {
  label: `Tiers transactions`,
  routerLink: ['/thirdpartyEntityIdentifier/list', {
    filter: FilterPropertiesUtils.serializeFilterPropertyValues([
      {
        property: ThirdPartyEntityIdentifierFilterProperties.anyIdentifierType,
        filterValue: [WsThirdPartyIdentifierType.Iban],
      }
    ])
  }],
  requiredEnabledFeatures: ["menu.thirdparty.visible"],
};


export const ThirdPartyMainItem: AppShellMenuItem = {
  label: `Tiers`,
  icon: `pi pi-users`,
  routerLink: ['/thirdpartyEntity/list'],
  requiredEnabledFeatures: ["menu.thirdparty.visible"],
  items: [
    ThirdPartyIdentifiersSubItem
  ]
};


// Problems

export const ALlDataProblemSubItem: AppShellMenuItem = {
  label: 'Tous les problèmes',
  routerLink: ['/problem', {filter: FilterPropertiesUtils.serializeFilterPropertyValues(WellKnownAccountingDataProblemsFilters.unresolved)}],
  requiredEnabledFeatures: ["menu.accountingDataProblem.visible"],
  totalCountBadgeFunction: api => countProblems$(api, WellKnownAccountingDataProblemsFilters.unresolved),
};

export const AccountingDataProblemThirdPartyToCreateSubItem: AppShellMenuItem = {
  label: `Tiers non répertoriés`,
  routerLink: ['/problem', {filter: FilterPropertiesUtils.serializeFilterPropertyValues(WellKnownAccountingDataProblemsFilters.thirdPartiesToCreate)}],
  requiredEnabledFeatures: ["menu.accountingDataProblem.thirdpartyToCreate.visible"],
  totalCountBadgeFunction: api => countProblems$(api, WellKnownAccountingDataProblemsFilters.thirdPartiesToCreate),
};

export const AccountingDataProblemIbanToCreateSubItem: AppShellMenuItem = {
  label: `Ibans non répertoriés`,
  routerLink: ['/problem', {filter: FilterPropertiesUtils.serializeFilterPropertyValues(WellKnownAccountingDataProblemsFilters.ibansToCreate)}],
  requiredEnabledFeatures: ["menu.accountingDataProblem.ibanToCreate.visible"],
  totalCountBadgeFunction: api => countProblems$(api, WellKnownAccountingDataProblemsFilters.ibansToCreate),
};
export const AccountingDataProblemFieldSubItem: AppShellMenuItem = {
  label: `Champ problématique`,
  routerLink: ['/problem', {filter: FilterPropertiesUtils.serializeFilterPropertyValues(WellKnownAccountingDataProblemsFilters.problematicField)}],
  totalCountBadgeFunction: api => countProblems$(api, WellKnownAccountingDataProblemsFilters.problematicField),
};
export const AccountingDataInconsistentCustomerFieldSubItem: AppShellMenuItem = {
  label: `Dossier incorrect`,
  routerLink: ['/problem', {filter: FilterPropertiesUtils.serializeFilterPropertyValues(WellKnownAccountingDataProblemsFilters.inconsistentCustomerProblem)}],
  totalCountBadgeFunction: api => countProblems$(api, WellKnownAccountingDataProblemsFilters.problematicField),
};
export const AccountingDataUnsupportedFileProblemSubItem: AppShellMenuItem = {
  label: `Fichier non supporté`,
  routerLink: ['/problem', {filter: FilterPropertiesUtils.serializeFilterPropertyValues(WellKnownAccountingDataProblemsFilters.unsupportedFileProblem)}],
  totalCountBadgeFunction: api => countProblems$(api, WellKnownAccountingDataProblemsFilters.problematicField),
};
export const AccountingDataThirdpartyMisspelledProblemSubItem: AppShellMenuItem = {
  label: `Nom du tiers mal orthographié`,
  routerLink: ['/problem', {filter: FilterPropertiesUtils.serializeFilterPropertyValues(WellKnownAccountingDataProblemsFilters.thirdpartyMisspelledFileProblem)}],
  totalCountBadgeFunction: api => countProblems$(api, WellKnownAccountingDataProblemsFilters.problematicField),
};
export const AccountingDataDocumentNotIndexableFileProblemProblemSubItem: AppShellMenuItem = {
  label: `Document non indexable`,
  routerLink: ['/problem', {filter: FilterPropertiesUtils.serializeFilterPropertyValues(WellKnownAccountingDataProblemsFilters.documentNotIndexableFileProblem)}],
  totalCountBadgeFunction: api => countProblems$(api, WellKnownAccountingDataProblemsFilters.problematicField),
};
export const AccountingDataWithMismatchedDocumentSubItem: AppShellMenuItem = {
  label: `Document mismatch`,
  routerLink: ['/customerDocument/list', {filter: FilterPropertiesUtils.serializeFilterPropertyValues(WellKnownCustomerDocumentFilters.mismatch)}],
  totalCountBadgeFunction: api => countProblems$(api, WellKnownCustomerDocumentFilters.mismatch),
};

export const AccountingDataProblemsMainItem: AppShellMenuItem = {
  label: `Problèmes`,
  icon: `pi pi-flag`,
  routerLink: ['/problem', {filter: FilterPropertiesUtils.serializeFilterPropertyValues(WellKnownAccountingDataProblemsFilters.unresolved)}],
  requiredEnabledFeatures: ["menu.accountingDataProblem.visible"],
  items: [
    AccountingDataProblemThirdPartyToCreateSubItem,
    AccountingDataProblemIbanToCreateSubItem,
    AccountingDataProblemFieldSubItem,
    AccountingDataInconsistentCustomerFieldSubItem,
    AccountingDataUnsupportedFileProblemSubItem,
    AccountingDataThirdpartyMisspelledProblemSubItem,
    AccountingDataDocumentNotIndexableFileProblemProblemSubItem,
    AccountingDataWithMismatchedDocumentSubItem
  ]
};


//
// Règles
//
export const RuleDashboardRoute: AppShellMenuItem = {
  label: `Aperçu`,
  routerLink: ['/configuration/rule'],
  requiredEnabledFeatures: ["menu.config.rules.visible"],
};
export const ConfigurationFiltres: AppShellMenuItem = {
  label: `Filtres`,
  routerLink: ShellRoutesUtils.combinedFilterListForFiltersFactory([{
    property: CombinedFilterFilterProperties.archived,
    filterValue: false,
  }]),
  requiredEnabledFeatures: ["menu.config.filters.visible"],
};
export const ConfigurationRules: AppShellMenuItem = {
  label: `Règles`,
  routerLink: ShellRoutesUtils.nitroRuleListForFiltersFactory([{
    property: NitroRuleFilterProperties.archived,
    filterValue: false,
  }]),
  requiredEnabledFeatures: ["menu.config.rules.visible"],
};
export const ConfigurationRuleActions: AppShellMenuItem = {
  label: `Actions`,
  routerLink: ShellRoutesUtils.nitroRuleActionsListForFiltersFactory([]),
  requiredEnabledFeatures: ["menu.config.rules.visible"],
};


export const RulesMainMenuItem: AppShellMenuItem = {
  label: `Ruling`,
  icon: 'pi pi-directions',
  routerLink: ['/configuration/rule'],
  requiredEnabledFeatures: ["menu.config.rules.visible"],
  items: [
    ConfigurationFiltres,
    ConfigurationRules,
    ConfigurationRuleActions,
  ],
};


//
// Configuration
//
export const ConfigurationDocumentTypeCollections: AppShellMenuItem = {
  label: `Sélections de types de doc`,
  routerLink: ShellRoutesUtils.nitroDocumentTypeCollectionListForFiltersFactory([]),
  requiredEnabledFeatures: ["menu.config.docTypeSelection.visible"],
};
export const ConfigurationBusinessSector: AppShellMenuItem = {
  label: `Secteurs d'activité`,
  routerLink: ['/businessSector'],
  requiredEnabledFeatures: ["menu.config.businessSector.visible"],
}
export const ConfigurationFinancialProvidersSector: AppShellMenuItem = {
  label: `Institutions financières`,
  routerLink: ['/financialProviders'],
  requiredEnabledFeatures: ["menu.config.visible"],
}
export const ConfigurationTrusteeAllSubItem: AppShellMenuItem = {
  label: `Fiduciaires`,
  routerLink: ['/trustee/list'],
  requiredEnabledFeatures: ["menu.trustee.visible"]
};

export const ConfigurationCustomerAllSubItem: AppShellMenuItem = {
  label: `Dossiers`,
  routerLink: ['/customer/list'],
  requiredEnabledFeatures: ["menu.customer.visible"]
};

export const ConfigurationNitroUserAllSubItem: AppShellMenuItem = {
  label: `Administration des utilisateurs`,
  routerLink: ['/user'],
  requiredEnabledFeatures: ["menu.customer.visible"]
}

export const ConfigurationBatchSubItem: AppShellMenuItem = {
  label: `Batch`,
  routerLink: ["/batch"],
  requiredEnabledFeatures: ["menu.config.batch.visible"]
};

export const ConfigurationMainItem: AppShellMenuItem = {
  label: `Configuration`,
  icon: 'pi pi-cog',
  requiredEnabledFeatures: ["menu.config.visible"],
  routerLink: ShellRoutesUtils.nitroConfigListForFiltersFactory([]),
  items: [
    ConfigurationTrusteeAllSubItem,
    ConfigurationCustomerAllSubItem,
    ConfigurationBusinessSector,
    ConfigurationFinancialProvidersSector,
    ConfigurationDocumentTypeCollections,
    ConfigurationBatchSubItem,
    ConfigurationNitroUserAllSubItem,
  ],
};


// Main menu
export const AdminFrontMainMenu: AppShellMenuItem[] = [
  AccountingDataMainItem,
  ThirdPartyMainItem,
  AccountingDataProblemsMainItem,
  RulesMainMenuItem,
  ConfigurationMainItem,
];
