import {NitroUserRouteComponent} from "./nitro-user-route/nitro-user-route.component";
import {CommonModule} from "@angular/common";
import {NitroUserRoutingModule} from "./nitro-user-routing.module";
import {AdminFrontSharedModule} from "../shared/admin-front-shared.module";
import {ButtonLinkModule, NitroRuleListModule, NitroUserListModule,NewUserFormInputModule} from "@fiscalteam/ngx-nitro-services";
import {ButtonModule} from "primeng/button";
import {NgModule} from "@angular/core";


@NgModule({
  declarations: [
    NitroUserRouteComponent,
  ],
  imports: [
    CommonModule,
    NitroUserRoutingModule,
    AdminFrontSharedModule,
    ButtonLinkModule,
    ButtonModule,
    NitroRuleListModule,
    NitroUserListModule,
    NewUserFormInputModule
  ]
})

export class NitroUserModule{}
